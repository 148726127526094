import { HomePageSEO, contentAPIRequest} from '@monorepo-nx/std-watch/services';
import {  ChartTop3, PostDetail, TableOfContents, TopProviderSection } from '@monorepo-nx/std-watch/ui';
import Image from 'next/image';
import {Layout} from '@monorepo-nx/std-watch/ui';
import { gql } from 'graphql-request';


const HomePage = ({ page, topProvider, seoData, mainMenu }) => {
  return (
    <Layout mainMenu={mainMenu}>
      <div className="max-w-8xl break-words mx-auto px-2 lg:px-4 lg:py-12">
        <HomePageSEO data={seoData} />
        <div className="md:grid h-fit md:grid-cols-12">
          
          <aside className="md:col-span-3 sm:col-span-1">  
          <div className='hidden sticky top-[100px] h-fit  lg:block'>
            <TableOfContents />
            </div>  
          </aside>

          <div className="lg:col-span-7 sm:col-span-10 sm:px-2 mx-4">
            <div className='lg:mb-10'>
              <h1 className="inline bg-gradient-to-r from-violet-900 via-indigo-800 to-indigo-900 bg-clip-text  font-display text-3xl tracking-tight text-transparent">
                {page.title} 
              </h1>
            </div>

            <div className="flex justify-end lg:hidden z-10 sticky top-14 mt-2">
              {/* Mobile */}
              <TableOfContents />
            </div>
            <Image
              priority
              height={310}
              width={726}
              src={page.headerImage ? page.headerImage.url : ""}
              alt={page.headerImage?.altText ? page.headerImage.altText : "Article image"}
              className="object-contain"
            />
            <PostDetail post={page} />
             {/* Mobile */}
            <div className="lg:hidden sm:mx-24 mb-16">
              <div className='mb-16'>
                <ChartTop3 />
              </div>
            </div>
          </div>
          <aside className="hidden sticky top-[100px] h-fit lg:col-span-2 lg:block">
          <ChartTop3 />
          </aside>
        </div>
      </div>
      <TopProviderSection promotion={topProvider} />
    </Layout>
  );
}

export default HomePage;

export async function getStaticProps() {
  // const stage = query.stage || 'PUBLISHED'
  // if (stage === 'PUBLISHED') {
  //     res.setHeader(
  //         'Cache-Control',
  //         'public, s-maxage=3600, stale-while-revalidate=59'
  //     )
  // }
  // else {
  //     res.setHeader(
  //         'Cache-Control',
  //         'public, s-maxage=0, stale-while-revalidate=0'
  //     )
  // }
  const stage = 'PUBLISHED'
  const query = gql`
        query GetPageView(
                $url: String!,
                $stage: Stage!,
                $width: Int!,
                $footer: String,
                $menu: String!,
            ) {
            page(where: {url: $url}, stage: $stage) {
                ...PageFields
            }
            footerData: navigationMenu(where: {name: $footer}, stage: $stage) {
                ...NavigationMenuFields
            }
            mainMenuData: navigationMenu(where: {name: $menu}, stage: $stage) {
                ...NavigationMenuFields
            }
  }`

  const variables = { 
      url: '/',
      stage,
      footer: "Footer",
      menu: "Main",
      width: 500
  }
  const {page, mainMenuData, footerData} = await contentAPIRequest(query, variables);
  
  const seoData = { 
    createdAt: "2021-09-30T16:00:00.000Z",
    updatedAt: "2021-09-30T16:00:00.000Z",
    seo: [{
    title: "The Best STD Testing Services in 2023",
    description: "STDwatch is your ultimate guide to home STD testing, as well as sexual and reproductive health. We put the research in so you don’t have to.",
    canonicalUrl: "https://www.stdwatch.com/",
    image: {
      url: "https://cdn.stdwatch.com/assets/optimized/std-backgrounds/STDWatch_Home.webp?q=80"
  }}]
   
  }


  return {
    props: {
      mainMenu: mainMenuData?.content,
      footer: footerData?.content,
      page,
      seoData,
      topProvider: page.defaultChart ? page.defaultChart.promotions[0] : null,
    },
    revalidate: 300,
  };
}